<template>
	<v-dialog v-model="dialog" fullscreen>
		<!-- dialog -->
		<div class="u-dialog-wrap pre-contract">
			<!-- header -->
			<div class="u-dialog-header">
				<h2 class="heading">추천 판매인 검색</h2>
				<v-btn depressed icon plain class="btn-close" @click="close"
					><v-icon dark>
						mdi-window-close
					</v-icon></v-btn
				>
			</div>
			<!-- //header -->

			<!-- contents -->
			<div class="dialog_all_page pa-6">
				<div class="u-search-phone">
					<!-- 추천 판매인 검색 -->
					<v-form
						class="form"
						ref="instRecommendSalesSearchForm"
						@submit.prevent="clickSearch"
					>
						<v-row class="sch-form">
							<v-text-field
								dense
								outlined
								height="48px"
								class="search wcalc90"
								inputmode="numeric"
								maxlength="11"
								v-mask="['###########']"
								placeholder="휴대폰 번호를 입력해 주세요."
								v-model="searchText"
								:rules="rules.searchText"
								@keyup.enter="clickSearch()"
							/>
							<v-btn
								class="ml-2 bor_default"
								height="48px"
								color="primary"
								depressed
								@click="clickSearch()"
							>
								검색
							</v-btn>
						</v-row>
					</v-form>
					<!--// 추천 판매인 검색 -->

					<!-- 검색 후 데이터 존재 -->
					<template v-if="!listEmptyView">
						<v-row
							class="sch-result"
							v-for="(sales, i) in searchSalesList"
							:key="i"
							@click="selectRecommendSales(sales)"
						>
							<dl>
								<dt>판매인명</dt>
								<dd>{{ sales.ENAME }}</dd>
							</dl>
							<dl>
								<dt>담당지국</dt>
								<dd>{{ sales.ZVKGTX_H3 }}</dd>
							</dl>
							<dl>
								<dt>연락처</dt>
								<dd>
									{{ $options.filters.phoneFormat(sales.NUM02, 0) }}
								</dd>
							</dl>
						</v-row>
					</template>
					<!-- // 검색 후 데이터 존재 -->

					<!-- 검색 후 데이터 없음 -->
					<v-row v-if="listEmptyView" class="sch-nodata">
						<p>
							검색 결과가 없습니다. <br />
							판매인의 휴대폰 번호를 확인해 주세요.
						</p>
					</v-row>
					<!-- //검색 후 데이터 없음 -->

					<!-- 메시지 -->
					<v-row class="sch-notice">
						<p>
							※ 추천 판매인의 휴대폰 번호를 입력한 후 검색하여 나온 판매인을
							선택해 주세요.
						</p>
					</v-row>
					<!--// 메시지 -->
				</div>
			</div>
		</div>
	</v-dialog>
</template>
<script>
import { minLength, phoneNumber } from '@/utils/rules'
import filters from '@/mixins/filters'
import { fetchRecommendSalesList } from '@/apis/order.inst.api'

export default {
	name: 'InstRecommendSalesSearch',
	mixins: [filters],
	props: {
		no: Number,
		recommnedSales: Object
	},
	data() {
		return {
			dialog: false,
			searchText: '',
			searchSalesList: [],
			listEmptyView: false,
			rules: {
				searchText: [
					v =>
						minLength(v, 11) === true
							? true
							: "'-' 를 제외한 11자리를 입력해주세요",
					v => phoneNumber(v)
				]
			},
			resolve: null,
			reject: null
		}
	},
	created() {
		// 폼 초기화
		this.reset()
	},
	methods: {
		// 초기화
		reset() {
			this.searchSalesList = []
			this.searchText = ''
			if (this.$refs.instRecommendSalesSearchForm) {
				this.$refs.instRecommendSalesSearchForm.resetValidation()
			}
			this.listEmptyView = false
		},

		// 추천판매인 검색 오픈
		open() {
			this.$log('recommnedSales :::', this.recommnedSales)
			this.dialog = true
			this.reset()
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},

		// 닫기
		close() {
			this.dialog = false
		},

		// 검색
		async clickSearch() {
			if (!this.$refs.instRecommendSalesSearchForm.validate()) {
				return
			}

			const result = await fetchRecommendSalesList({
				I_SALES_MOB: this.searchText
			})

			this.searchSalesList = result.resultObject.ET_SALES

			this.listEmptyView = this.searchSalesList.length === 0 ? true : false
		},

		async selectRecommendSales(obj) {
			const reSales = JSON.parse(JSON.stringify(this.recommnedSales))
			Object.assign(reSales, { ...obj })
			this.$emit('selectRecommendSales', {
				salesInfo: reSales,
				index: this.no
			})
		}
	}
}
</script>
