<template>
	<v-dialog v-model="dialog" fullscreen>
		<div class="full_dialog_header">
			<h2 class="tit">주소지 관리</h2>
			<v-btn depressed icon plain class="btn_right" @click="close"
				><v-icon dark>
					mdi-window-close
				</v-icon></v-btn
			>
		</div>
		<div class="dialog_all_page pa-6">
			<div class="u-addr-manage">
				<ul>
					<template v-if="addrList && addrList.length > 0">
						<li
							v-for="(addr, i) in addrList"
							:key="i"
							@click="selectAddress(addr, i)"
						>
							<div class="heading">
								<em class="primary--text">
									{{ addrTypeText(addr.TYPE) }}
								</em>
								<v-btn
									icon
									v-if="addr.TYPE === 'N'"
									@click.stop="removeAddr(i)"
								>
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</div>
							<div class="address">
								{{ addr.ADDR1 }}
								{{ custInfos[i] === true ? addr.ADDR2 : '*'.repeat(10) }}
							</div>
							<div class="toggle">
								<div @click.stop="listFill(i)">
									<v-switch
										inset
										class="inner_text"
										v-model="custInfos[i]"
										:label="
											custInfos[i] === true ? `고객정보 열람` : `고객정보 숨김`
										"
									>
									</v-switch>
								</div>
							</div>
						</li>
					</template>
					<template v-else>
						<li>최근 주소지가 없습니다.</li>
					</template>
				</ul>
			</div>
			<div class="bottom_box mt-6">
				<v-btn color="primary" class="col_1_small" @click="openAddrSearch">
					새 주소지 추가
				</v-btn>
			</div>
			<InstAddrSearch :ref="'addrSearch'" />
		</div>
	</v-dialog>
</template>
<script>
import filters from '@/mixins/filters'
import InstAddrSearch from '@/views/inst/InstAddrSearch'

export default {
	mixins: [filters],
	props: {
		orderCount: Number,
		addrList: Array
	},
	components: {
		InstAddrSearch
	},
	data() {
		return {
			dialog: false,
			// switch: []
			custInfos: []
		}
	},
	methods: {
		mask(val) {
			// this.$log(this.$options.filters.addr(val))
			return this.$options.filters.name(val)
		},
		open() {
			this.dialog = true
		},
		close() {
			this.dialog = false
		},
		async selectAddress(addr, index) {
			const ok = await this.$confirm({
				message: `선택한 주소를 다시 한번 확인해 주시기 바랍니다. 해당 주소로 주문 및 설치를 진행하시겠습니까?<br/><br/>${addr.ADDR1} ${addr.ADDR2}`,
				okText: '확인',
				cancelText: '취소'
			})

			if (ok) {
				this.$emit('select', {
					addr: addr,
					index: index
				})
				this.close()
			} else {
				return
			}
		},
		// 주소입력
		async openAddrSearch() {
			const newAddrCount = this.addrList.filter(x => x.TYPE === 'N').length

			if (newAddrCount >= this.orderCount) {
				await this.$alert({
					message: `더 이상 새 주소지를 추가할 수 없습니다.
						${this.orderCount}건 주문 시 ${this.orderCount}곳의 새 주소지를 등록할 수 있습니다.`
				})
				return
			}

			const addr = await this.$refs.addrSearch.open()
			this.addrList.push({
				TYPE: 'N', // 신규주소지
				ZIP_SEQ: addr.zipSeq,
				ADDR1: addr.roadAddress,
				ADDR2: addr.detailAddress,
				POST_CODE: addr.zonecode
			})

			this.custInfos[this.addrList.length - 1] = true
			this.listFill(this.addrList.length - 1)
		},
		addrTypeText(type) {
			let typeText = ''
			if (type === 'B') {
				typeText = '기본주소지'
			} else if (type === 'R') {
				typeText = '최근주소지'
			} else {
				// if (type === 'N') {
				typeText = '신규주소지'
			}
			return typeText
		},
		async removeAddr(idx) {
			const ok = await this.$confirm({
				message: '신규주소지를 삭제 하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (!ok) {
				return
			}
			this.addrList.splice(idx, 1)
		},
		listFill(index) {
			const indexVal = this.custInfos[index]
			this.custInfos.fill(false)
			this.$set(this.custInfos, index, indexVal)
		}
	}
}
</script>

<style lang="scss">
.v-input.inner_text.v-input--switch--inset .v-input--switch__track,
.v-input.inner_text.v-input--switch--inset .v-input--selection-controls__input {
	width: 118px;
	height: 32px;
	border-radius: 20px;
}

.v-input.inner_text.v-input--switch--inset.v-input--is-label-active
	.v-input--switch__track {
	background: #1b9edb !important;
	opacity: 1;
}

.v-input--selection-controls__ripple,
.v-application--is-ltr
	.v-input--switch--inset.v-input.inner_text.v-input--is-dirty
	.v-input--switch__thumb {
	width: 24px;
	height: 24px;
	transform: translate(87px, 0) !important;
	background: #fff;
}

.theme--ligh.v-input.inner_text.v-input--switch--inset
	.v-input--selection-controls__ripple,
.v-application--is-ltr .v-input--switch--inset .v-input--switch__thumb {
	width: 24px;
	height: 24px;
}

.v-application--is-ltr
	.v-input--switch--inset.inner_text
	.v-input--selection-controls__ripple {
	opacity: 0;
}

.v-input.inner_text.v-input--is-label-active.v-input--is-dirty.theme--light {
	position: relative;
}

.inner_text.v-input--selection-controls .v-radio > .v-label,
.inner_text.v-input--selection-controls .v-input__slot > .v-label {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute !important;
	top: 2px;
	width: 100%;
	height: 32px;
	padding-left: 10px;
	font-size: 13px;
	color: #fff;
	line-height: 32px;
}

.inner_text.v-input--is-label-active.v-input--selection-controls
	.v-input__slot
	> .v-label {
	left: -18px !important;
	right: 0 !important;
}

.inner_text .v-input__slot,
.inner_text .v-input--selection-controls__input {
	margin: 0;
}

.v-input--selection-controls.v-input.inner_text {
	display: flex;
	position: absolute !important;
	right: 24px;
	bottom: 16px;
	margin-bottom: 0 !important;
}

.box_result2 .result li .v-btn {
	padding-bottom: 56px !important;
}
</style>
